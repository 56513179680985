import React from 'react';
import CalendarComponent from '../calendar/calendaer';

const Home: React.FC = () => {
  return (
    <div className="bg-black min-h-screen pb-20">
      {/* Section: Design Block */}
      <section>
        <div className="px-6 py-12 text-center md:px-12 lg:text-left">
          <div className="container mx-auto">
            <div className="grid items-center gap-12 lg:grid-cols-2">
              <div className="mt-12 lg:mt-0">
                <h1 className="mb-12 text-5xl font-bold tracking-tight text-[hsl(218,81%,95%)] md:text-6xl xl:text-7xl">
                  Are you ready to<br /><span className="text-[hsl(218,81%,75%)]">unleash?</span>
                </h1>
                <p className="text-gray text-lg text-[hsl(218,81%,95%)]" style={{ fontFamily: '"Alfa Slab One", serif', textShadow: "2px 2px 4px black" }}>
                  Unleash the potential for <span className='text-purple'>personal growth</span> and global immersion by engaging in our programs that celebrate the richness of Chinese culture <span className='text-blue'>through martial arts</span>.
                </p>
              </div>
              <div className="lg:mb-0">
                <div className="embed-responsive embed-responsive-16by9 relative w-full overflow-hidden rounded-lg shadow-lg"
                  style={{ paddingTop: '56.25%' }}>
                  <iframe className="embed-responsive-item absolute top-0 right-0 bottom-0 left-0 h-full w-full"
                    src="https://youtube.com/embed/IpK8nm8zd3c"
                    allowFullScreen={true} data-gtm-yt-inspected-2340190_699="true" id="240632615"></iframe>
                </div>
              </div>
            </div>
          </div>
          <CalendarComponent />
        </div>
      </section>
      <div>
      </div>
      {/* Section: Design Block */}
    </div>
  );
};

export default Home;
