import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/appRoutes';
function App() {
  const handleClickOutside = (event: MouseEvent) => {
    if (event.target instanceof Element && !event.target.closest('footer')) {
      const captchaElement = document.getElementById('captcha');
      if (captchaElement) {
        captchaElement.classList.add('hidden');
      }
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className="App bg-black">
      <Router>
        <AppRoutes />
      </Router>
      {/*The componenets below should be on the training center- component not created yet */}
      {/*<ReviewList />*/}
    </div>
  );
}

export default App;
