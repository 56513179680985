import React, { useState } from 'react';
import CustomModal from '../common/modal';
import shuaijiao from '../assets/imgs/shuaijiao2.jpg';
import bjj from '../assets/imgs/bjj.jpg';
import taichi from '../assets/imgs/taichi.jpg';
import discipleship from '../assets/imgs/discipleship.jpg';
import privateTraining from '../assets/imgs/privateTraining.jpg';
import jwmethod from '../assets/imgs/jwmethod.jpg';
import CalendlyWidget from './calendly/calendly';

interface ClassInfo {
  title: string;
  subtitle: string;
  description: string;
  schedule: string;
  scheduleBtn: string;
  img: string;
  fee: string;
}

const CardGroup: React.FC = () => {
  const classInfo: ClassInfo[] = [
    { title: 'Chinese Boxing', scheduleBtn: "See Schedule", fee: "$29.99 Two-Week Intro Courses", subtitle: '拳法', schedule: 'https://calendly.com/jingwupai', img: privateTraining, description: "Chinese Boxing incorporates the dynamic elements of Sanda's explosive kicks and throws alongside the strategic boxing precision of Xingyiquan's Form-Intention Fist. This martial discipline is a blend of impactful techniques of striking and grappling similar to MMA." },
    { title: 'Chinese Wrestling', scheduleBtn: "See Schedule", fee: "$100 - 4 Week Cohorts", subtitle: '摔跤', schedule: 'https://calendly.com/jingwupai/chinese-wrestling', img: shuaijiao, description: 'Shuaijiao, an ancient Chinese wrestling art, skillfully blends strength and technique, drawing parallels to the principles of Judo and Sambo. Characterized by controlled throws and strategic takedowns, Shuaijiao refines both physical prowess and tactical acumen.'  },
    { title: 'Brazilian Jiu-jitsu', scheduleBtn: "See Schedule",fee: "$100 - 4 Week Cohorts", subtitle: '柔术', schedule: 'https://calendly.com/jingwupai/brazilian-jiu-jitsu', img: bjj, description: "Grounded in leverage and technique, BJJ empowers practitioners to navigate and control ground situations effectively. Delve into the art of submission holds and positional dominance, cultivating mental resilience alongside physical prowess. " },
    { title: 'Jingwu Method', scheduleBtn: "View Online Course", fee: "Online Subscription Coming Soon", subtitle: '京武方法', schedule: 'https://raphaelsmith.podia.com/view/courses/jingwu-method', img: jwmethod, description: 'Embark on a profound journey with our online program, meticulously crafted for Neigong—mastering internal energy. Move beyond routine form practice, delving into the intricacies of internal energy development. Explore the harmonious union of breath and movement, unlocking profound internal power. Experience holistic well-being and self-discovery as you cultivate a deep connection to this ancient martial art.' },
    { title: 'Tai Chi', scheduleBtn: "See Schedule", fee: "$29.99 Two-Week Intro Courses" , subtitle: '太极拳', schedule: 'https://calendly.com/jingwupai/tai-chi', img: taichi, description: 'Tai Chi, a venerable Chinese cultural art, transcends martial origins to become a holistic health practice. Its graceful, flowing movements promote physical well-being, stress reduction, and balance. Rooted in ancient traditions, Tai Chi harmonizes mind and body, offering a serene journey towards holistic cultivation.' },
    { title: 'Discipleship Program',scheduleBtn: "See Schedule", fee: "Free for teens. *Special conditions apply", subtitle: '传统宋氏形意拳', schedule: 'https://calendly.com/jingwupai/discipleship-program', img: discipleship, description: "Step into a unique martial arts experience with our traditional Xingyiquan program, specifically designed to elevate students for 'Bai Shi' (拜师)—a ceremonial milestone in accepting a master and becoming a lineage holder of the art. This program goes beyond our regular programs, emphasizing traditional Chinese culture, preparation to inherit a martial tradition, and the discipline required for being a lifelong martial artist. It underscores the profound significance of Bai Shi in shaping a dedicated and respectful martial artist." },
  ];
  const [isHovered, setIsHovered] = useState(false);
  const [selectedModalInfo, setSelectedModalInfo] = useState<ClassInfo | null>(null);

  const openModal = (info: ClassInfo) => {
    setSelectedModalInfo(info);
  };

  const closeModal = () => {
    setSelectedModalInfo(null);
  };
  const handleButtonClick = () => {
    // Logic to be executed when the button is clicked in the modal
    // You can add your own logic here
    console.log("Button in modal clicked!");
  };
  return (
    <div id='courses' className="p-5 overflow-hidden">
      <h1 className="mb-4 text-xl tracking-tight leading-none text-white md:text-2xl lg:text-3xl" style={{ fontFamily: '"Alfa Slab One", serif', textShadow: "2px 2px 4px black" }}>
        Training Center <span className='text-blue'>Courses</span>
      </h1>
      <hr className='w-1/2 mx-auto mb-10' style={{ color: "white" }} />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-3/4 mx-auto items-center justify-center w-3/4 xs:mb-24 sm:mb-24 md:mb-24 lg:mb-24 mb-20 sm:mb-48">
        {classInfo.map((info, index) => (
          <div key={index} className="relative"
          >
            {/* Card */}
            <div
              className={`relative bg-white w-full h-80 rounded shadow-md flex card text-grey-darkest overflow-hidden transition duration-300 transform hover:scale-95 `}
              onClick={() => openModal(info)} // Open the modal on click
            >
              <div className="absolute inset-0">
                <img className="w-full h-full object-cover object-center" src={info.img} alt="Room Image" />
              </div>
              {/* Dark Overlay using ::before pseudo-element */}
              <div className="absolute inset-0 bg-black opacity-0 hover:opacity-50 transition-opacity"></div>
              <div className="absolute inset-0 flex flex-col justify-center items-center p-4"
              >
                <span className="text-5xl text-gray hover:text-white" style={{ fontFamily: '"Alfa Slab One", serif', textShadow: "2px 2px 4px black", position: 'relative', zIndex: 1 }}>{info.title}</span>
                <div className="flex items-center mt-2">
                  <div className="pr-2 text-4xl text-white" style={{ fontFamily: '"Liu Jian Mao Cao", cursive', textShadow: "2px 2px 4px black" }}>
                    {info.subtitle}
                  </div>
                </div>
              </div>
            </div>

            {/* Modal */}
            {selectedModalInfo && (
              <CustomModal
                martialArt={selectedModalInfo.title}
                description={selectedModalInfo.description}
                fee={selectedModalInfo.fee} // You can provide the appropriate fee or add it to the ClassInfo interface
                schedule={selectedModalInfo.schedule}
                closeModal={closeModal}
                scheduleBtn={selectedModalInfo.scheduleBtn}
                onButtonClick={handleButtonClick}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardGroup;