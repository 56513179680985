import React, { useState } from 'react';
import StarIcon from './StarRating'; // Make sure to include the StarIcon component
import { reviewData } from './ReviewData';

const Testimonials: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? reviewData.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex === reviewData.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="container mx-auto md:px-6 text-grey" style={{ fontFamily: '"Alfa Slab One", serif', marginBottom: '4rem' }}>
      <section className="text-center p-12 testimonial-section">
        <hr className="w-1/2 mx-auto mb-10" style={{ color: 'white' }} />
        <h2 className="mb-12 text-5xl">Testimonials</h2>
        <hr className="w-3/4 mx-auto mb-10" style={{ color: 'white' }} />
        <div
          id="carouselExampleCaptions"
          className="relative w-full overflow-hidden after:clear-both after:block after:content-['']"
          data-te-carousel-init
          data-te-carousel-slide
        >
          {reviewData.map((review, index) => (
            <div
              key={index}
              className={`relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none ${
                index === activeIndex ? 'hidden' : ''
              }`}
              data-te-carousel-active
              data-te-carousel-item
              style={{ backfaceVisibility: 'hidden', textShadow: "2px 2px 4px black" }}
            >
               <div className="w-[80%] sm:w-[80%] md:w-[85%] lg:w-full xl:w-full mx-auto">
              <div className="flex flex-wrap justify-center">
                <div className="w-full shrink-0 grow-0 basis-auto px-3 lg:w-8/12">
                  <h5 className="mb-2 text-lg text-blue">{review.name}</h5>
                  <p className="mb-4" style={{ textShadow: "2px 2px 3px black" }}>{review.role}</p>
                  <p className="mb-6">{review.content}</p>
                  <ul className="mb-0 flex justify-center">
                    {[...Array(5)].map((_, i) => (
                      <li key={i}>
                        <StarIcon filled={i < review.rating} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            </div>
          ))}
          <button
            className="absolute top-0 bottom-0 left-0 z-[1] flex items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="prev"
            onClick={handlePrev}
          >
            <span className="inline-block h-8 w-8">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                className="text-neutral-600 dark:text-neutral-300"
              >
                <path fill="currentColor" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Previous
            </span>
          </button>
          <button
            className="absolute top-0 bottom-0 right-0 z-[1] flex items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="next"
            onClick={handleNext}
          >
            <span className="inline-block h-8 w-8">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                className="text-neutral-600 dark:text-neutral-300"
              >
                <path fill="currentColor" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
