import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AboutUs from 'src/components/aboutUs/aboutUs';
import TrainingCenter from 'src/pages/trainingCenter';
import Navbar from '../common/nav';
import Footer from '../common/footer';
import ContactSection from '../components/contactUs/contactUs';
import TeamSection from '../components/members/members';
import Home from '../components/home/homePage';
import Donate from '../components/donate/donate';

const AppRoutes: React.FC = () => {
  return (
    <div>
      <Navbar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />{/* Add more routes as needed */}
            <Route path='/membership' element={<TeamSection />} />
            <Route path="/trainingcenter" element={<TrainingCenter/>} />
            <Route path="/donate" element={<Donate/>} />
            <Route path='/contact' element={<ContactSection />} />
        </Routes>
      <Footer />
    </div>
  );
};

export default AppRoutes;
