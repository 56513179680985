import React from 'react';
import './calendly.styles.css';

const CalendarComponent: React.FC = () => {
  return (
    <>
      <div className="mt-4 text-purple grid grid-cols-12 gap-4 p-4 md:p-12 " style={{ fontFamily: '"Alfa Slab One", serif' }}>
        {/* Schedule information on the left (col-12 for xs, col-6 for md and above) */}
        <div className="col-span-12 md:col-span-6 text-white" style={{ alignSelf: 'center' }}>
          <div className="text-md mt-4 lg:mt-0 text-justify md:text-left text-xl" style={{ textShadow: "2px 2px 4px black" }}>
            <span className="text-2xl text-gray">INVEST IN YOURSELF</span>
            <br />
            Personalized guidance crafted specifically for your unique goals and abilities.
            <br />
            <br />
            <div className="flex flex-wrap items-center mt-4">
              <div className="w-full lg:w-2/5 text-center md:text-left mb-4">
              <a href="https://calendly.com/jingwupai" target="_blank" rel="noopener noreferrer">
                <button className="w-full text-gray border rounded hover:bg-black hover:text-white hover:border-white border-solid border-gray w-3rd lg:w-full py-2">
                View Schedule
                </button>
              </a>
              </div>
              <p className="text-gray text-sm md:w-3/5 pl-4" style={{ fontFamily: '"Alfa Slab One", serif' }}>
                Private & semi-private training sessions in any course we offer.
              </p>
            </div>
          </div>
        </div>
        {/* Calendar on the right (hidden for xs, col-6 for md and above) */}
        <div className="hidden md:col-span-6 md:w-3/4 mx-auto">
          {/* Adjust the content based on your requirements */}
        </div>
      </div>
    </>
  );
};

export default CalendarComponent;
