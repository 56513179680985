import React, { useState } from "react";
import SingleImage from "../SingleImg";
import Wudangwest from "../../assets/imgs/Wudangwest.jpg";
import teammarques from '../../assets/imgs/teammarques.png';
import kungfusauce from '../../assets/imgs/kfsauce.png';

const Affiliates = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="bg-gray border-black-light py-4 dark:bg-dark" style={{
      borderWidth: "2px",
      boxShadow: "inset 0 0 10px rgba(0, 0, 0, 5.9)",
    }}>
      <h1 className="pb-10 text-3xl tracking-tight leading-none text-black md:text-4xl lg:text-5xl" style={{ fontFamily: '"Alfa Slab One", serif' }}>
        Affiliates & Brands
      </h1>
      <div className="">
        <div className="flex flex-wrap mx-4">
          <div className="w-full px-4">
            <div className="flex flex-wrap items-center justify-center">
              <a
                href="https://www.wudangwest.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={`mb-4 ${hoveredIndex === 0 ? 'bounce' : ''}`}
                onMouseEnter={() => handleMouseEnter(0)}
                onMouseLeave={handleMouseLeave}
              >
                <SingleImage
                  Alt="Brand Image"
                  imgSrc={Wudangwest}
                />
              </a>
              <a
                href="https://www.instagram.com/teammarques_/"
                target="_blank"
                rel="noopener noreferrer"
                className={hoveredIndex === 1 ? 'bounce' : ''}
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}
              >
                <SingleImage
                  Alt="Brand Image"
                  imgSrc={teammarques}
                />
              </a>
                <SingleImage
                  Alt="Brand Image"
                  imgSrc={kungfusauce}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affiliates;