import React from 'react';
import Ralph from '../../assets/imgs/traditional.jpg'
import Linglin from '../../assets/imgs/linglin.jpg'

const TeamSection: React.FC = () => {
  return (
    <section className="bg-black text-gray pb-20" style={{ fontFamily: '"Alfa Slab One", serif' }}>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
        <div className="mx-auto text-center mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl text-white">Jing Wu <span className="">Foundation Board</span></h2>
          <p className="font-light lg:mb-16 sm:text-xl">
            Our board members embody our non-profit's <span className='text-purple'>commitment to</span> <span className='text-blue'>cultural exchange</span> through martial arts. 
            Dedicated to <span className='text-blue'>community building</span>, they connect local and global communities by exploring and <span className='text-blue'>promoting 
            Chinese culture</span> in our area. United by a vision of a harmonious society, 
            they work as catalysts for positive change, fostering mutual respect and understanding in a vibrant world.
          </p>
        </div> 
        <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
          {/* Team Member 1 */}
          <div className="items-center bg-white rounded-lg shadow sm:flex">
          <img className="hidden sm:block rounded-lg sm:rounded-none sm:rounded-l-lg w-full sm:w-2/4 h-full" src={Ralph} alt="Ralph" /> 
            <div className="p-5">
              <h3 className="text-xl text-black">
                <p>Raphael Smith</p>
              </h3>
              <span className="text-sm text-black" style={{ textShadow: "1px 1px 1px gray"}}>Director & Head Instructor</span>
              <p className="text-xs text-black" style={{ textShadow: "1px 1px 1px gray"}}>raphaelsmith@jingwupai.org</p>
              <br/>
              <p className="mt-3 mb-4 text-black text-xs"style={{textAlign: 'left'}}>MA Fuller Theological Seminary</p>
              <p className="mt-3 mb-4 text-black text-xs" style={{textAlign: 'left'}}>BA Andrews University</p>
              <p className="mt-3 mb-4 text-black text-xs" style={{textAlign: 'left'}}>5th Generation Song Family Boxing Lineage Holder</p>
              <p className="mt-3 mb-4 text-black text-xs" style={{textAlign: 'left'}}>5th Duan China's Wushu Association</p>
              <p className="mt-3 mb-4 text-black text-xs" style={{textAlign: 'left'}}>Certified Coach of Beijing Xingyiquan Research Association</p>
            </div>
          </div>

          {/* Team Member 2 */}
          <div className="items-center bg-white rounded-lg shadow sm:flex">
          <img className="hidden sm:block rounded-lg sm:rounded-none sm:rounded-l-lg w-full sm:w-2/4 h-full" src={Linglin} alt="Linglin" />
            <div className="p-5">
              <h3 className="text-xl text-black">
                <p>Chang Linglin</p>
              </h3>
              <span className="text-sm text-black" style={{ textShadow: "1px 1px 1px gray"}}>Chief Financial Officer & Chinese Culture Director</span>
              <p className="text-xs text-black" style={{ textShadow: "1px 1px 1px gray"}}>linglin@jingwupai.org</p>
              <br/>
              <p className="mt-3 mb-4 text-black text-xs"style={{textAlign: 'left'}}>BA University Colorado Denver</p>
              <p className="mt-3 mb-4 text-black text-xs" style={{textAlign: 'left'}}>BA China Agricultural University</p>
              <p className="mt-3 mb-4 text-black text-xs"style={{textAlign: 'left'}}>Experienced Translator</p>
              <p className="mt-3 mb-4 text-black text-xs"style={{textAlign: 'left', paddingBottom: "110px"}}>Freelance Artist in Chinese Brush Painting and Calligraphy</p>
            </div>
          </div>

          {/* Team Member 3 */}
          

          {/* Team Member 4 */}
          
        </div>
        </div>
   </section>       
  );
};

export default TeamSection;
