import mma1 from '../../assets/vids/mma1.mp4'
import disciple2 from '../../assets/vids/disciple.mp4';
import privates3 from '../../assets/vids/privates.mp4';

export const heroVideos = [mma1, disciple2, privates3];

export const heroData = [
  { title: 'Combat Sport', subtitle: 'Train to be Unrivaled', button: 'Courses', url: '#courses' },
  { title: 'Internal Cultivation', subtitle: 'Discover the Internal Body', button: 'Jingwu Method', url: 'https://raphaelsmith.podia.com/view/courses/jingwu-method' },
  { title: 'Personal Training', subtitle: '1-to-1 Sessions', button: 'Book Private Lesson', url: 'https://calendly.com/jingwupai/privates' },
];
