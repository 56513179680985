import React from 'react';

const ContactSection: React.FC = () => {
  const googleFormUrl = process.env.REACT_APP_GOOGLE_FORM_URL || '';
  return (
    <div className="py-8 sm:py-12 lg:py-16 mb-40 md:mb-20"  style={{ fontFamily: '"Alfa Slab One", serif', backgroundColor: 'white' }}>
      <div className="max-w-7xl mx-auto px-4 text-center">
      <h2 className="text-3xl font-extrabold text-black mb-8">Contact Us</h2>
        <p className="mt-3 text-lg text-black">
          Whether you're seeking information, have inquiries, or just want to share your thoughts,
          we're available for you.
        </p>
        <ul className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <li className="flex flex-col items-center">
            <div className="h-10 w-10 flex items-center justify-center rounded text-purple mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
              </svg>
            </div>
            <div>
              <h3 className="mb-2 text-lg font-medium leading-6">Address</h3>
              <p className="text-gray">2610 Churn Creek Rd, Suite C</p>
              <p className="text-gray">Redding, CA, 96002</p>
            </div>
          </li>
          <li className="flex flex-col items-center">
            <div className="w-10 h-10 flex items-center justify-center rounded text-purple mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                <path d="M15 7a2 2 0 0 1 2 2"></path>
                <path d="M15 3a6 6 0 0 1 6 6"></path>
              </svg>
            </div>
            <div>
              <h3 className="mb-2 text-lg font-medium leading-6">Phone</h3>
              <p className="text-gray">+1 (916) 707-4373</p>
              <a
                href={googleFormUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-sm px-4 py-2 leading-none border rounded text-gray border-gray hover:border-black hover:text-black mt-2 sm:mt-4"
              >
                Send Message
              </a>
            </div>
          </li>
          <li className="flex flex-col items-center">
            <div className="h-10 w-10 flex items-center justify-center rounded bg-blue-900 text-purple mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                <path d="M12 7v5l3 3"></path>
              </svg>
            </div>
            <div>
              <h3 className="mb-2 text-lg font-medium leading-6">Center Hours</h3>
              <p className="text-gray mb-2">Monday - Thursday: 9:00am - 9:00pm</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactSection;
