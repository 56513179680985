import React, { useRef, useState, useEffect } from 'react';
import logo from '../assets/imgs/logo2.png';

const Jumbotron: React.FC = () => {
  const bgLogoRef = useRef<HTMLDivElement | null>(null);
  const h1Ref = useRef<HTMLHeadingElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    // Set up an interval to call moveImg every 5000 milliseconds
    const intervalId = setInterval(moveImg, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // The empty dependency array ensures this runs only once on mount

  // Function to move the background image and apply fade effect
  const moveImg = () => {
    setTimeout(() => {
    // Check if the ref has a current value
        if (bgLogoRef.current) {
        bgLogoRef.current.classList.toggle('fade-out');
            // Change the class name based on the current value
            if (bgLogoRef.current.classList.contains('bg-left')) {
                bgLogoRef.current.classList.replace('bg-left', 'bg-right');
            } else if (bgLogoRef.current.classList.contains('bg-right')) {
                bgLogoRef.current.classList.replace('bg-right', 'bg-left');
            } else {
                // If the class name is not one of the expected values, set it to 'bg-left'
                bgLogoRef.current.classList.add('bg-center');
            }
            bgLogoRef.current.classList.toggle('fade-in');
        }
    }, 2000);
    bgLogoRef.current?.classList.toggle('fade-out');
  };


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  return (
    <div
      className={`relative h-48 md:h-72 lg:h-84 xl:h-100 2xl:h-132 overflow-hidden mx-20 mb-4 bg-black}`}
      onMouseEnter={handleMouseEnter}
    >
      <div
        ref={bgLogoRef}
        className={`absolute w-full h-full bg-left bg-no-repeat bg-blend-multiply fade-in ${isHovered ? 'flicker' : ''}`}
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: '12%',
          transition: 'opacity 2s ease-in-out',
          filter: 'invert(15%)', // Invert the colors
        }}
      />
      <div className="px-4 mx-auto max-w-screen-xl text-center p-12">
        <h1
          ref={h1Ref}
          className={`mb-4 text-2md md:text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl ${isHovered ? 'flicker-h1' : ''}`}
          style={{ fontFamily: '"Liu Jian Mao Cao", cursive', color: isHovered ? 'gray' : 'gray' }}
        >
          京   武   派
        </h1>
        <p
          className={`text-sm md:text-lg font-normal lg:text-xl sm:px-16 lg:px-48 ${isHovered ? 'flicker' : ''}`}
          style={{
            fontFamily: '"Alfa Slab One", serif',
            textShadow: isHovered ? '2px 2px 5px #FFA500' : 'none',
            color: isHovered ? 'white' : 'gray',
          }}
        >
          Holistic development for enhanced strength, flexibility, and mindfulness through a guided martial arts journey.
        </p>
        <style>
          {`
            @keyframes flickerTextShadow {
              0%, 25%, 50%, 75%, 100% {
                text-shadow: 2px 2px 5px #FFA500;
              }
              12.5%, 37.5%, 62.5%, 87.5% {
                text-shadow: none;
              }
            }

            @keyframes flickerColor {
              0%, 25%, 50%, 75%, 100% {
                color: gray;
              }
              12.5%, 37.5%, 62.5%, 87.5% {
                color: gray;
              }
            }

            @keyframes flickerH1Color {
              to {
                color: white;
              }
            }

            .flicker {
              animation: flickerTextShadow 1s ease-in-out 2, flickerColor 1s ease-in-out 2;
            }

            .flicker-h1 {
              animation: flickerH1Color 3.3s ease-in-out forwards;
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default Jumbotron;
