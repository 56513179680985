import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CalendlyWidget from 'src/components/calendly/calendly';

interface ModalProps {
  martialArt: string;
  description: string;
  fee: string;
  schedule: string;
  scheduleBtn: string;
  closeModal: () => void;
  onButtonClick: () => void; // Define onButtonClick in ModalProps
}

const CustomModal: React.FC<ModalProps> = ({ martialArt, description, fee, closeModal, schedule, scheduleBtn, onButtonClick }) => {
  const [modalOpen, setModalOpen] = useState(true);

  const closeAndToggleModal = () => {
    closeModal();
    setModalOpen(!modalOpen);
  };

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex={-1}
      aria-hidden="true"
      className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
        modalOpen ? '' : 'hidden'
      } overflow-y-auto overflow-x-hidden z-50 w-full md:w-[80%] max-w-2xl`}
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full text-white" style={{ fontFamily: '"Alfa Slab One", serif' , letterSpacing: "1px", textShadow: "1px 1px 3px black", fontSize: "smaller" }}>
        {/* Modal content */}
        <div className="relative background-radial-gradient rounded-lg shadow dark:bg-gray-700">
        <style>
        {`.background-radial-gradient {
            background-color: hsl(0, 0%, 0%); /* Black */
            background-image: radial-gradient(650px circle at 0% 0%,
                hsl(0, 0%, 100%) 0%, /* White */
                hsl(0, 0%, 50%) 20%, /* Lighter Gray */
                hsl(0, 0%, 40%) 40%, /* Light Gray */
                hsl(0, 0%, 30%) 60%, /* Gray */
                hsl(0, 0%, 20%) 80%, /* Dark Gray */
                hsl(0, 0%, 10%) 100%); /* Darker Gray */
          }`}
        </style>
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl">
              {martialArt}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="static-modal"
              onClick={closeAndToggleModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-4 md:p-5 space-y-4">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {description}
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {fee}
            </p>
          </div>
          {/* Modal footer */}
          <div className="flex items-center justify-center p-4 md:p-5 border-t border-gray rounded-b dark:border-gray-600">
            {/* Link to open Calendly widget */}
            <Link
              data-modal-hide="static-modal"
              type="button"
              style={{ textShadow: 'none' }}
              className="ms-3 bg-transparent text-gray rounded-lg border border-gray hover:border-white text-sm font-medium px-5 py-2.5 hover:text-white focus:z-10"
              to={(scheduleBtn === "View Online Course") ? schedule : ""}
            >
              {(scheduleBtn === "View Online Course") ? scheduleBtn : <CalendlyWidget onButtonClick={onButtonClick} url={schedule} close={closeModal} />}
            </Link>
            {/* CalendlyWidget to show the Calendly widget */}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
