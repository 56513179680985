import React, { useState, useEffect } from 'react';
import announcements from './announcement'; // Use the original name

const Announcements: React.FC = () => {
  const [currentAnnouncement, setCurrentAnnouncement] = useState<number>(0);
  const [showBanner, setShowBanner] = useState(true);

  const closeBanner = () => {
    setShowBanner(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAnnouncement((prevIndex) =>
        prevIndex < announcements.length - 1 ? prevIndex + 1 : 0
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='w-full'>
      {showBanner && (
        <div
          id="informational-banner"
          tabIndex={-1}
          className="hidden sm:block bg-gray text-white p-4 sticky top-0 z-50 w-full"
          style={{ overflow: 'hidden' }}
        >
          <div
            className={`announcement ${
              currentAnnouncement === 0 ? 'active' : ''
            }`}
            style={{
              height: '80%',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between', // Align to left and right
              alignItems: 'flex-end', // Align to the bottom
            }}
          >
            <div className="flex items-center">
              <div className="mb-4 md:mb-0 md:me-4">
                <h1 className="flex items-center text-2xl font-normal text-white dark:text-black" style={{ fontFamily: '"Alfa Slab One", serif', textShadow: '2px 2px 4px black', }}>
                  {announcements[currentAnnouncement]}
                </h1>
              </div>
            </div>
            <div className="flex items-center">
              <a
                href="/contact"
                className="inline-flex items-end justify-center text-sm font-medium text-white bg-transparent hover:text-black hover:bg-transparent relative"
                style={{ fontFamily: '"Alfa Slab One", serif' }}
              >
                Contact for more info{' '}
                <svg
                  className="w-3 h-3 ms-2 rtl:rotate-180 mb-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </a>
            </div>
            <button
              onClick={closeBanner}
              type="button"
              className="absolute top-0 end-0 mt-2 me-2 text-white hover:text-black text-sm"
            >
              <svg
                className="w-2 h-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close banner</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Announcements;
