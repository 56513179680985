import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  function handleClick() {
    const captchaElement = document.getElementById('captcha');
    if (captchaElement) {
      captchaElement.classList.remove('hidden');
    } else {
      console.error("Element with ID 'captcha' not found");
    }
  }
  function handleClickHide() {
    const captchaElement = document.getElementById('captcha');
    if (captchaElement) {
      captchaElement.classList.add('hidden');
    } else {
      console.error("Element with ID 'captcha' not found");
    }
  }

  function handleCaptchaResponse() {
    const captchaElement = document.getElementById('sib-captcha');
    if (captchaElement) {
      const event = new Event('captchaChange');
      captchaElement.dispatchEvent(event);
    } else {
      console.error("Element with ID 'sib-captcha' not found");
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?hl=en';
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      // Clean up: remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <footer className='text-center bg-black-light opacity-95 text-white py-2' style={{ fontFamily: '"Alfa Slab One", serif', position: 'fixed', width: '100%', bottom: 0, zIndex: 100 }}>
      <div className='container mx-auto'>
        <section className='hidden sm:block'>
          <form id="sib-form" method="POST" action="https://84085d23.sibforms.com/serve/MUIFAIpbPIT8qp0IEagxwFM95URhb2KdUJ6dDDDbcmtZszhv32VAAdCFZutxwxzNGoxVqdau5ORdpufujWSebx59ra5wu4GAPi9Mb7Gd9Cfenyo7kaxbd30LReqCfv4-7kur7pkxvM6WJR51kQgjMVJmrPF0e49cgzXcayUbgbfFspd6AwQG2L9xs0G0tl_prM9XsdZpd7a88FKW">
          <div className='grid grid-cols-1 md:grid-cols-3 items-start'>
              <label
                className='col-span-1 text-gray align-self-start m-2 mr-3 md:mr-4'
                htmlFor="EMAIL"
                data-required="*"
              >
                Subscribe to our newsletter
              </label>
              <input
                className='col-span-1 p-2 m-2 w-3/4 md:w-full ml-12 md:ml-0 rounded-md border border-white focus:outline-none text-sm text-gray'
                type="text"
                id="EMAIL"
                name="EMAIL"
                autoComplete="off"
                placeholder="Email Address"
                data-required="true"
                required
                onClick={handleClick}
              />
              <button
                className="inline-block text-sm px-4 py-3 m-2 leading-none border rounded text-gray border-gray hover:border-white hover:text-white hover:bg-transparent w-3/4 ml-12"
                style={{ fontFamily: '"Alfa Slab One", serif' }}
                form="sib-form"
                type="submit"
                onClick={handleClickHide}
              >
                SUBSCRIBE
              </button>
              <input type="text" name="email_address_check" value="" className="hidden" />
              <input type="hidden" name="locale" value="en" />
              <input type="hidden" name="html_type" value="simple" />
              <div className='col-span-1 flex justify-center space-x-6'>
                <a className='text-gray hover:text-white' href='https://www.facebook.com/jingwupai'>
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a className='text-gray hover:text-white' href='https://www.youtube.com/@jingwupai' target='_blank' rel='noopener noreferrer'>
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a className='text-gray hover:text-white' href='https://www.instagram.com/jingwupai/'>
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
          </form>
          {/* Add the subscription form here */}
          <div id='captcha' className='pb-2 hidden'>
            <div className="sib-captcha sib-form-block text-center">
              <div className="form__entry entry_block">
                <div className="form__label-row">
                  <div className="g-recaptcha sib-visible-recaptcha" id="sib-captcha" data-sitekey="6LfPXoUpAAAAAO4_NtESXfUiDXlea0be1nHQxIjg" data-callback="handleCaptchaResponse" style={{ display: 'inline-block' }}></div>
                </div>
                <label className="entry__error entry__error--primary" style={{ fontSize: '16px', textAlign: 'left', fontFamily: '"Helvetica", sans-serif', color: '#661d1d', backgroundColor: '#ffeded', borderRadius: '3px', borderColor: '#ff4949' }}></label>
                <label className="entry__specification" style={{ fontSize: '12px', fontFamily: '"Helvetica", sans-serif', color: '#8390A4', textAlign: 'left' }}>
                  Form secured by reCAPTCHA
                </label>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className='text-center text-gray text-xs'>
        © 2023 Jing Wu Foundation. All rights reserved. | A 501(c)(3) Nonprofit Organization
      </div>
    </footer>
  );
}

export default Footer;
