import React, { useRef, useEffect, useState } from 'react';

interface HeroProps {
  videos: string[];
  videoInfo: {
    title: string;
    subtitle: string;
    button: string;
    url: string;
  }[];
}

const Hero: React.FC<HeroProps> = ({ videos, videoInfo }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [activeDotIndex, setActiveDotIndex] = useState(0);

  useEffect(() => {
    const playNextVideo = () => {
      if (videoRef.current) {
        videoRef.current.src = videos[currentVideoIndex];
        videoRef.current.load();
        videoRef.current.addEventListener('ended', videoEndedHandler);
      }
    };

    const videoEndedHandler = () => {
      if (videoRef.current) {
        setCurrentVideoIndex((prev) => (prev + 1) % videos.length);
        setActiveDotIndex((prev) => (prev + 1) % videos.length);
        videoRef.current.removeEventListener('ended', videoEndedHandler);
        playNextVideo();
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('canplay', () => {
        videoRef.current?.play();
      });
    }

    playNextVideo();

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('canplay', () => {
          videoRef.current?.play();
        });
        videoRef.current.removeEventListener('ended', videoEndedHandler);
      }
    };
  }, [videos, currentVideoIndex]);

  const handleDotClick = (index: number) => {
    setCurrentVideoIndex(index);
    setActiveDotIndex(index);
  };

  return (
    <div className="relative h-48 md:h-72 lg:h-96 xl:h-112 2xl:h-144 overflow-hidden bg-black">
      <video ref={videoRef} className="hero-video w-full h-full object-cover transition-opacity absolute top-0 left-0" autoPlay muted playsInline>
        <source src={videos[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white z-10">
        <h1 className="text-md mb-4 text-gray md:text-4xl" style={{ fontFamily: '"Alfa Slab One", serif' }}>{videoInfo[currentVideoIndex].title}</h1>
        <p className="text-md md:text-4xl" style={{ fontFamily: '"Alfa Slab One", serif' }}>{videoInfo[currentVideoIndex].subtitle}</p>
        <div className="mt-4">
          <a
            href={videoInfo[currentVideoIndex].url}
            className="inline-block text-sm px-4 py-2 leading-none border rounded text-gray border-gray hover:border-white hover:text-white hover:bg-transparent"
            style={{ fontFamily: '"Alfa Slab One", serif' }}
          >
            {videoInfo[currentVideoIndex].button}
          </a>
        </div>
        <div className={`mt-8 flex justify-center items-end px-4 lg:items-center lg:mt-20`}>
          {videos.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === activeDotIndex ? 'active-dot' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hero;

