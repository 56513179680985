// CalendlyWidget.tsx
import React, { useEffect } from 'react';

interface WindowWithCalendly extends Window {
  Calendly: any; // Adjust the type based on Calendly's actual structure
}

interface CalendlyWidgetProps {
  onButtonClick: () => void;
  url: string;
  close: () => void;
}

const CalendlyWidget: React.FC<CalendlyWidgetProps> = ({ onButtonClick, url, close }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <button
        onClick={() => {
          if ((window as unknown as WindowWithCalendly).Calendly) {
            (window as unknown as WindowWithCalendly).Calendly.initPopupWidget({ url: url });
            close();
          }
          onButtonClick();
        }}
      >
        See Our Schedule
      </button>
    </div>
  );
};

export default CalendlyWidget;
