export const reviewData = [
  {
    name: "Jon Nicklin",
    date: 'November 13, 2023',
    content: '“Raphael Smith is one of the few Westerners I have ever met who is not only able to reproduce some of the seemingly ‘magical’ internal power skills of the older generation of teachers, but perhaps more importantly, to explain and teach those skills in a step-by-step way in modern terminology, without any of the hocus-pocus which usually clouds these discussions. Before coming to Raphael I had already studied xingyi for close to 10 years but although I had modelled the external shape of the movements well I was frustrated that I was unable to reproduce the internal power (jin) skills I could feel from older teachers. In relatively short 1-2 hours sessions Raphael was able to completely remodel my understanding of how the legs, pelvis, backbone & central axis and hands should coordinate to start generating at least basic internal power. For me it really was a lightbulb moment, and I would wholeheartedly recommend anyone who is interested in getting better at internal arts (especially xingyi) to check Raphael out.”', 
    rating: 5,
    avatar: 'none',
    role: 'Martial Artist'
  },
  {
    name: "Jonathan Chou",
    date: "October 28, 2023",
    content: '"During the week I spent in private training with the Jing Wu Pai Training Center, they answered all of my questions regarding the nature of Xingyiquan and Neigong(internal skill) in practical, easily understandable western terminology. At the same time we worked on basic combat fitness, striking, wrestling, and ground fighting, to make sure I would have tools to uild good "external fighting habits". I greatly appreciate the time I spent there receiving an introduction to the Jing Wu Pai training methodology. Raphael Smith is an excellent teacher and I look forward to training under him in the future."',
    rating: 5,
    avatar: 'none',
    role: "Massage Therapist"
  }

];