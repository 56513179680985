import * as React from 'react';
import logo from '../assets/imgs/New_JWP_Logo.png';
import { Link } from 'react-router-dom';
import Announcement from '../components/announcements/announcements';

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  function handleClick() {
    const captchaElement = document.getElementById('captcha');
    if (captchaElement) {
      captchaElement.classList.add('hidden');
    } else {
      console.error("Element with ID 'captcha' not found");
    }
  }

  return (
    <>
      <nav className="shadow-md flex items-center justify-between flex-wrap bg-black-light opacity-90  sticky top-0 z-50">
        <Link className="flex items-center flex-shrink-0 text-white" to="/">
          <img
            src={logo}  // Update with the correct path to your SVG file
            alt="Logo"
            className="fill-current h-16 w-16"
          />
        
        <span className='text-white' style={{ fontFamily: '"Alfa Slab One", serif' }}>Jing Wu Foundation</span>
        </Link>
        <div className="block lg:hidden">
          <button
            onClick={toggleMobileMenu}
            className="flex items-center px-3 py-2"
          >
            <button
              onClick={toggleMobileMenu}
              className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-black hover:bg-white"
            >
              &#9776; {/* Unicode character for hamburger icon */}
            </button>
          </button>
        </div>
        <div
          className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
            isMobileMenuOpen ? 'block' : 'hidden'
          }`}
          style={{ fontFamily: '"Alfa Slab One", serif' }}
        >
          <div className="text-sm lg:flex-grow">
            <Link
              onClick={() => {
                handleClick();
                closeMobileMenu(); // Close the mobile menu
              }}
              className="block mt-4 lg:inline-block lg:mt-0 text-gray hover:text-white mr-4"
              to="/home"
            >
              Home
            </Link>
            <Link
              onClick={() => {
                handleClick();
                closeMobileMenu(); // Close the mobile menu
              }}
              className="block mt-4 lg:inline-block lg:mt-0 text-gray hover:text-white mr-4"
              to="/about"
            >
              About
            </Link>
            <Link
              onClick={() => {
                handleClick();
                closeMobileMenu(); // Close the mobile menu
              }}
              className="block mt-4 lg:inline-block lg:mt-0 text-gray hover:text-white mr-4"
              to="/membership"
            >
              Board and Members
            </Link>
            <Link
              onClick={() => {
                handleClick();
                closeMobileMenu(); // Close the mobile menu
              }}
              className="block mt-4 lg:inline-block lg:mt-0 text-gray hover:text-white mr-4"
              to="/trainingcenter"
            >
              Training Center
            </Link>
            <Link
              onClick={() => {
                handleClick();
                closeMobileMenu(); // Close the mobile menu
              }}
              className="block mt-4 lg:inline-block lg:mt-0 text-gray hover:text-white mr-4"
              to="/donate"
            >
              Donate
            </Link>
            <Link
              onClick={() => {
                handleClick();
                closeMobileMenu(); // Close the mobile menu
              }}
              className="block mt-4 lg:inline-block lg:mt-0 text-gray hover:text-white mr-4"
              to="/contact"
            >
              Contact
            </Link>
          </div>
        </div>
        <Announcement />
      </nav>
    </>
  );
}

export default Navbar;
