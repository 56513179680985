import React, { useState, useEffect } from 'react';

import logo from '../../assets/imgs/New_JWP_Logo.png';
import JWP from '../../assets/imgs/IMG_1188.png';

const AboutUs: React.FC = () => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(0); // 1 for down, -1 for up

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setScrollDirection(currentScrollY > scrollY ? 1 : -1);
    setScrollY(currentScrollY);
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return (
    <>
      <section className="pt-10 overflow-hidden pb-48" style={{ fontFamily: '"Alfa Slab One", serif' }}>
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid items-center grid-cols-1 md:grid-cols-2">
            <div>
              <h2 className="text-2xl leading-tight text-white sm:text-4xl lg:text-3xl">
                <span className="mt-4 text-blue">A Non Profit</span> Martial Art Center
              </h2>
              <p className="mt-3 text-gray" style={{ textShadow: "2px 2px 4px black" }}>
                Our mission is to promote a Chinese cultural exchange through language and martial arts. We are dedicated to 
                fostering opportunities for community building, engagement, and collaboration with our local community with the purpose 
                of creating a third space culture of diversity.
              </p>
            </div>

            <div className="relative">
              <img className="absolute inset-x-0 bottom-0 -mb-48 -translate-x-1/2 left-1/2" src="https://cdn.rareblocks.xyz/collection/celebration/images/team/1/blob-shape.svg" alt="" />
              <img className="relative w-full xl:max-w-lg xl:mx-auto 2xl:origin-bottom 2xl:scale-110" src={JWP} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className='py-20 bg-black'></section>
      <section className="overflow-hidden bg-gray-50 pb-0 sm:pb-40" style={{ fontFamily: '"Alfa Slab One", serif' }}>
  <div className="sm:flex items-center max-w-screen-xl pb-40 md:pb-0">
    <div className="relative hidden sm:block"> {/* Hide on mobile view */}
      <img className="relative w-full xl:mx-auto 2xl:origin-bottom 2xl:scale-110" src={logo} alt="Logo" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
    </div>
    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div className="text-gray">
        <h2 className="text-2xl leading-tight text-white sm:text-4xl lg:text-3xl">About <span className="text-purple">Our Vision</span></h2>
        <p className="mt-3 text-gray" style={{ textShadow: "2px 2px 4px black" }}>
          We envision a future where our society is united through a shared appreciation
          for cultural diversity and mutual respect. Our vision is to be a catalyst for positive change,
          empowering individuals to embrace cultural richness, celebrate differences, and contribute to a more
          interconnected and vibrant world.
        </p>
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default AboutUs;
