import React from 'react';
import ReviewList from '../Reviews/ReviewList';

const Donate: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-gray" style={{ fontFamily: '"Alfa Slab One", serif' }}>
      <div className="my-10 w-full md:w-4/5 lg:w-3/4 xl:w-2/3 bg-white rounded-lg md:rounded-lg">
        <div className="flex flex-col md:flex-row h-full w-full">
          <div className="h-full p-3 rounded-lg w-full md:w-1/2 bg-white flex flex-col justify-center items-center">
            <p className="font-semibold text-xl mt-10 text-black text-center">Donate</p>
            <p className="text-black text-sm mt-5 text-center">
              Your generous donation fuels our mission to provide essential equipment, free programs to the community, bring in expert teachers, and send teens on life-changing cultural excursions to China. Be a catalyst for growth, empowerment, and cultural exchange. Help us shape the leaders of tomorrow—donate now
            </p>
            <div className="my-4"> 
              <a href="https://buy.stripe.com/eVa8xZ1eCaqufnO000" className="h-10 w-32 p-2 border rounded text-black border-black hover:border-blue hover:text-blue">Donate Now</a> 
            </div>
          </div>
          <div className="h-full hidden md:block relative md:rounded-lg overflow-hidden w-full">
            <img className="h-full w-full object-cover max-h-96 mx-auto" src="https://imgur.com/2hR32WP.jpg" alt="Donate Image" />
          </div>
        </div>
      </div>
      <ReviewList />
    </div>
  );
};

export default Donate;
