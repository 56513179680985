import React from 'react';

interface SingleImageProps {
  href?: string;
  imgSrc: string;
  Alt: string;
}

const SingleImage: React.FC<SingleImageProps> = ({ href, imgSrc, Alt }) => {
  return (
    <>
      <a
        href={href}
        className="mx-12 flex items-center justify-center"
        style={{ width: '187.5px', height: 'auto' }} // Adjusted width to be 25% bigger
      >
        <img src={imgSrc} alt={Alt} className="w-full h-auto" style={{ maxWidth: '100%' }} />
      </a>
    </>
  );
};

export default SingleImage;
