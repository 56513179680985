import Hero from "../components/hero/hero";
import CardGroup from "../components/cardGroup";
import Jumbotron from "../components/jumbotron";
import Affiliates from "../components/affiliates/affiliates";
import { heroData } from "../components/hero/heroVideoData"; 
import { heroVideos } from "../components/hero/heroVideoData";

const TrainingCenter = () => {
    const events = [
        { title: 'Event 1', start: '2024-01-01' },
        { title: 'Event 2', start: '2024-01-05', end: '2024-01-07' },
        // Add more events as needed
      ];
    return ( 
        <>
            <Hero videos={heroVideos} videoInfo={heroData} />
            <Jumbotron/>
            <Affiliates />
            <CardGroup />
        </>
     );
}
 
export default TrainingCenter;